import * as React from "react"
import DefaultPageHeader from "../components/DefaultPageHeader/DefaultPageHeader"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import TricolorMorphDef from "../components/TricolorMorphDef/TricolorMorphDef"
import { PageComponent } from "../types/gatsby.types"

const PageInsights: PageComponent = props => {
  return (
    <PageTemplate
      {...props} // always include
      className="PageInsights"
      title="Insights"
      description="Insights: Latest news, articles and insights from AxonDivision."
    >
      <TricolorMorphDef />
      <DefaultPageHeader
        id="InsightsPageHeader"
        title="Insights"
      >
      </DefaultPageHeader>
    </PageTemplate>
  )
}

export default PageInsights
